import React from 'react';
import Generic from '../../../components/287634/success/generic';

const OnboardingData = () => {
  return (
    <Generic
      h1={`¡Tachán!<br />Empieza a silbar. Tu registro fue un éxito.`}
      h2="Datos personales"
      p='Vuelve a WhatsApp y verifica tu email para operar de forma segura.'
      stepperNum={4}
      imgSrc="/theme/assets/images/feedback/silbar-success.svg"
    />
  );
};

export default OnboardingData;
